import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';

const PostCard = (props) => {
  const [cardStatus, setCardStatus] = useState('review');
  const [cardFlip, setCardFlip] = useState(0);
  const useStyles = makeStyles({
    cardContainer: {
      width: '159px',
      height: '196px',
      margin: '20px 10px 80px 10px',
      overflow: 'visible',
      position: 'relative',
      transformStyle: 'preserve-3d',
      transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
      cursor: 'pointer',
    },
    cardImage: {
      width: 'auto',
      height: '100%',
      boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.8)',
      backfaceVisibility: 'hidden',
      transform: 'rotateY(0deg)',
    },
    buttonContainer: {
      width: '100%',
      position: 'absolute',
      bottom: '20px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    cardButton: {
      display: 'flex',
      width: 50,
      height: 50,
      borderRadius: '50%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'space-around',
      cursor: 'pointer',
      margin: '0 10px',
    },
    cardButtonReset: {
      display: 'flex',
      width: 50,
      height: 50,
      borderRadius: '50%',
      backgroundColor: '#fff',
      color: '#333',
      alignItems: 'center',
      justifyContent: 'space-around',
      cursor: 'pointer',
    },
    cardOverlayRejected: {
      backgroundColor: 'rgba(252,88,48,0.7)',
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'all 0.2s ease-out',
    },
    cardOverlayApproved: {
      backgroundColor: 'rgba(80,172,175,0.7)',
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'all 0.2s ease-in',
    },
    cardOverlay: {
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'all 0.2s ease-in',
    },
    cardBack: {
      border: '4px solid #fff',
      boxSizing: 'border-box',
      overflow: 'hidden',
      backgroundColor: '#333',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      backfaceVisibility: 'hidden',
      transform: `rotateY(${cardFlip + 180}deg)`,
      transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
      color: '#fff',
      display: 'flex',
      padding: '10px',
    },
    cardFront: {
      boxSizing: 'border-box',
      border: '4px solid #fff',
      overflow: 'hidden',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#333',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      backfaceVisibility: 'hidden',
      transform: `rotateY(${cardFlip}deg)`,
      transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
    },
    cardStatus: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      marginTop: '50%',
      fontWeight: 'bold',
      fontSize: '22px',
      borderTop: '1px solid #fff',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      padding: '10px 0',
      borderBottom: '1px solid #fff',
      textTransform: 'uppercase',
      transform: 'translateY(-50%)',
      flex: 1,
      color: '#fff',
      position: 'absolute',
      zIndex: 1,
      transition: 'all 0.3s',
    },
    cardStatusReview: {
      display: 'none',
    },
    buttonIcon: {
      color: '#fff',
    },
    small: {
      width: 30,
      height: 30,
      border: '1px solid rgb(255,118,104)',
      boxShadow: '0px 0px 5px 2px rgba(255,119,104,1)',
    },
    username: {
      color: '#fff',
      margin: '5px 0 5px 0',
      paddingBottom: '5px',
      borderBottom: '1px solid #666',
    },
    backList: {
      fontSize: '10px',
      margin: '4px 0',
    },
  });
  const classes = useStyles();

  const sendPost = (fullpost) => {
    props.getUserInfo(fullpost);
    props.clickHandler();
  };

  const setApproved = () => {
    props.setStatusApproved(props.postid);
    setCardStatus('approved');
  };

  const setRejected = () => {
    props.setStatusRejected(props.postid);
    setCardStatus('rejected');
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={classes.cardContainer}
        onClick={() => sendPost(props.fullPost)}
      >
        <div className={classes.cardFront}>
          <div
            className={
              localStorage.getItem(props.postid) === 'approved'
                ? classes.cardOverlayApproved
                : localStorage.getItem(props.postid) === 'rejected'
                ? classes.cardOverlayRejected
                : classes.cardOverlay
            }
          >
            <div
              className={
                localStorage.getItem(props.postid) === null
                  ? classes.cardStatusReview
                  : classes.cardStatus
              }
            >
              {localStorage.getItem(props.postid)}
            </div>
          </div>
          <img className={classes.cardImage} src={props.image} alt='' />
        </div>
        <div className={classes.cardBack}>
          <Avatar
            alt='Remy Sharp'
            src={props.avatar}
            className={classes.small}
          />
          <h6 className={classes.username}>{props.username}</h6>
          <div style={{ alignContent: 'flex-start', flexDirection: 'column' }}>
            <p className={classes.backList}>
              <strong>Posted: </strong>
              {new Date(props.created * 1000).toUTCString()}
            </p>
          </div>
        </div>
        <div
          className={
            localStorage.getItem(props.postid) === 'approved'
              ? classes.cardOverlayApproved
              : localStorage.getItem(props.postid) === 'rejected'
              ? classes.cardOverlayRejected
              : classes.cardOverlay
          }
        >
          <div
            className={
              localStorage.getItem(props.postid) === null
                ? classes.cardStatusReview
                : classes.cardStatus
            }
          >
            {localStorage.getItem(props.postid)}
          </div>
        </div>
      </div>
      {!props.isInSidebar ? (
        <div className={classes.buttonContainer}>
          <div className={classes.cardButton} onClick={() => setApproved()}>
            <CheckIcon
              style={{ color: '#333' }}
              className={classes.buttonIcon}
            />
          </div>
          <div className={classes.cardButton} onClick={() => setRejected()}>
            <CloseIcon
              style={{ color: '#333' }}
              className={classes.buttonIcon}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PostCard;
