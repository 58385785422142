import React from 'react';

export default class ReviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {}

  render() {
    console.log(this.props);

    return (
      <React.Fragment>
        <div style={{ position: 'relative' }} onClick={this.props.onClick}>
          <div
            style={{
              width: '159px',
              height: '196px',
              margin: '20px 10px',
              overflow: 'visible',
              position: 'relative',
              transformStyle: 'preserve-3d',
              transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                border: '4px solid #fff',
                overflow: 'hidden',
                display: 'flex',
                flex: 1,
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                backfaceVisibility: 'hidden',
              }}
            >
              <img
                style={{
                  width: 'auto',
                  height: '100%',
                  boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.8)',
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(0deg)',
                }}
                src={this.props.post.postUrlSrc.medium}
                alt=''
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
