import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// // LIVE
// export const firebaseConfig = {
//   apiKey: 'AIzaSyCTH_fDu3XroXzpHez9wpBrsbN_131JVhk',
//   authDomain: 'keep-or-toss-dev.firebaseapp.com',
//   databaseURL: 'https://keep-or-toss-dev.firebaseio.com',
//   projectId: 'keep-or-toss-dev',
//   storageBucket: 'keep-or-toss-dev.appspot.com',
//   messagingSenderId: '639598605669',
//   appId: '1:639598605669:web:c5796622b4fede270ddd8c',
// };

// DEMO
export const firebaseConfig = {
  apiKey: 'AIzaSyAOJqlbtsGa5PhfvFuuT9blKd6VIVLksHs',
  authDomain: 'keep-or-toss.firebaseapp.com',
  databaseURL: 'https://keep-or-toss.firebaseio.com',
  projectId: 'keep-or-toss',
  storageBucket: 'keep-or-toss.appspot.com',
  messagingSenderId: '98406443346',
  appId: '1:98406443346:web:ed4602c90f105f9a0d328b',
  measurementId: 'G-RR1G5PBX43',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
