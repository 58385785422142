import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function DialogSelect({ user, role, newrole }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [listRole, setListRole] = React.useState();
  const [updatedRole, setUpdatedRole] = React.useState();

  const handleChange = (event) => {
    setUpdatedRole(String(event.target.value));
    setListRole(updatedRole);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setListRole(role);
  };

  const handleUpdate = () => {
    newrole(user, updatedRole);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='default'
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Define User Role for <br />
          <small>{user}</small>
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='demo-dialog-native'>Role</InputLabel>
              <Select
                native
                value={listRole}
                onChange={handleChange}
                input={<Input id='demo-dialog-native' />}
              >
                <option aria-label='None' value='Role' />
                <option value={'moderator'}>Moderator</option>
                <option value={'admin'}>Administrator</option>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleUpdate} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
