import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function EventDropdown(props) {
  const classes = useStyles();
  const [event, setEvent] = React.useState('');

  const handleChange = (event) => {
    console.log('setting.., ', event.target.value);
    props.filterevent(event.target.value);
    setEvent(event.target.value);
  };

  const listItems = props.events.map((event, index) => (
    <MenuItem value={event.title} key={index}>
      {event.title}
    </MenuItem>
  ));

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id='demo-simple-select-label'>Event</InputLabel>
        <Select
          labelId='event-select-label'
          id='event-select'
          value={event}
          onChange={handleChange}
        >
          <MenuItem value='all' selected>
            All
          </MenuItem>
          {listItems}
        </Select>
      </FormControl>
    </div>
  );
}

export default EventDropdown;
