import React from 'react';
import ReviewCard from '../../Components/UI/ReviewCard/ReviewCard';
import ReviewSidebar from '../../Components/UI/ReviewSidebar/ReviewSidebar';
import Pagination from '../../Components/UI/Pagination/Pagination';
import { db } from '../../FirebaseConfig';

export default class ReviewRequests extends React.Component {
  constructor() {
    super();
    this.state = {
      clickItem: null,
      setClickItem: null,
      posts: [],
      currentPage: 1,
      postsPerPage: 12,
    };
    this.db = db;
  }

  async componentDidMount() {
    await this.getPendingPosts(10, null);

    if (this.state && this.state.posts) {
      this.setClickItem = this.state.posts[0];
    }
  }

  async getPendingPosts(total, startDate) {
    if (this.cancelListen) this.cancelListen();
    this.cancelListen = this.db
      .collection('posts')
      .where('approvalStatus', '==', 'inReview')
      .where('isDeleted', '==', false)
      .limit(total)
      .onSnapshot((snapshot) => {
        this.setState({
          setClickItem: snapshot.docs[0] && snapshot.docs[0].id,
          posts:
            snapshot.docs &&
            snapshot.docs.map((doc) => ({ postID: doc.id, data: doc.data() })),
        });
      });
  }

  setSideBarItem(id) {
    this.state.setState({ setClickItem: id });
    this.forceUpdate();
  }

  render() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = this.state.posts.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    console.log(
      '%ccurrent posts',
      'background-color:cyan;color:magenta;',
      currentPosts
    );

    // Change page
    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    console.log(this.state.currentPage);

    if (this.state && this.state.posts === 0) {
      return <div>Loading...</div>;
    }

    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              width: '100%',
              alignSelf: 'stretch',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingTop: '100px',
                zIndex: 2,
              }}
            >
              {currentPosts.map((post) => {
                return (
                  <ReviewCard
                    onClick={() => {
                      this.setSideBarItem(post.postID);
                    }}
                    key={post.postID}
                    id={post.postID}
                    page={this.state.currentPage}
                    post={post.data}
                  />
                );
              })}
            </div>

            <div></div>
            <Pagination
              postsPerPage={this.state.postsPerPage}
              totalPosts={this.state.posts.length}
              paginate={paginate}
              currentPage={this.state.currentPage}
            />
          </div>
          <div
            style={{
              color: '#333',
              padding: '40px 20px',
              width: '550px',
              backgroundColor: '#d6d6d6',
              alignSelf: 'stretch',
              height: 'calc(100vh - 64px)',
              overflowY: 'scroll',
              zIndex: 2,
            }}
          >
            <ReviewSidebar
              key={this.state.setClickItem}
              index={this.state.setClickItem}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
