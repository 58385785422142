import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import PostCard from '../PostCard/PostCard';

export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (props.opendrawer === true) {
      setState({ ...state, right: true });
    }
  }, [props.opendrawer]);

  const eventTitle = props.eventlist.filter((evt) => {
    for (let tag of props.selectedpost.data.tags)
      if (evt.tags && evt.tags.includes(tag)) return true;
    return false;
  });

  const handleClose = () => {
    setState({ ...state, right: false });
    props.closedrawer();
  };

  const list = (anchor) => (
    <div
      style={{
        paddingTop: '10px',
        width: '350px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: '60px',
        padding: '20px',
        backgroundColor: '#fff',
        perspective: '4400px',
        perspectiveOrigin: '50% 50%',
        justifyContent: 'flex-start',
      }}
    >
      {props.opendrawer ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <PostCard
              isInSidebar={true}
              image={props.selectedpost.data.postUrlSrc.medium}
              userid={props.selectedpost.data.userId}
              status={props.selectedpost.data.approvalStatus}
              postid={props.selectedpost.postID}
              username={props.selectedpost.data.username}
              avatar={props.selectedpost.data.avatarUrlSrc.medium}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                color: '#333',
                margin: '5px 0 5px 0',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              Tags:
            </span>
            {props.selectedpost.data.tags &&
              props.selectedpost.data.tags.map((item) => (
                <span
                  key={item}
                  style={{
                    color: '#333',
                    margin: '5px 0 5px 0',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    paddingLeft: '12px',
                  }}
                >
                  #{item}{' '}
                </span>
              ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                color: '#333',
                margin: '5px 0 5px 0',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              Events:
            </span>
            <span
              style={{
                color: '#333',
                margin: '5px 0 5px 0',
                fontWeight: 'normal',
                fontSize: '14px',
                paddingLeft: '12px',
              }}
            >
              {eventTitle && eventTitle.length > 0 ? eventTitle[0].title : ''}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>post</Button> */}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => handleClose()}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
