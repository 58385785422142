import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import swal2 from 'sweetalert2';
import Adduser from '../../Components/UI/UserActions/Adduser';
import EditRole from '../../Components/UI/UserActions/EditRole';
import DeleteUser from '../../Components/UI/UserActions/DeleteUser';
import { db, functions } from '../../FirebaseConfig';

export default class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      portalUsers: [],
      didUpdate: false,
    };
    this.db = db;
    this.createData = this.createData.bind(this);
    this.editRole = this.editRole.bind(this);
  }

  createData(name, role) {
    return { name, role };
  }

  async componentDidMount() {
    this.getUsers();
    console.log('using effect');
  }

  async getUsers() {
    let adminusers = await this.db
      .collection('users')
      .where('isPortalUser', '==', true)
      .get();
    console.log(
      '%cadmin users',
      'color:white;background-color:blue;',
      adminusers
    );
    adminusers.forEach((user) => {
      this.setState((previousState) => ({
        portalUsers: [...previousState.portalUsers, user.data()],
      }));
      console.log(this.state.portalUsers);
    });
  }

  async editRole(user, updatedRole) {
    console.log(
      '%cnew role :',
      'background-color:red;color:black;',
      user,
      updatedRole
    );
    let assignRole = await functions.httpsCallable('assignRole');
    assignRole({ email: user, role: updatedRole })
      .then(function (result) {
        // Read result of the Cloud Function.
        window.location.reload();
      })
      .catch(function (error) {
        // Getting the Error details.
        let code = error.code;
        let message = error.message;
        let details = error.details;

        swal2.fire({
          icon: 'error',
          title: error.message,
          text: error.details,
        });

        console.log(code, message, details);
        // ...
      });
  }

  testRole(user, updatedRole) {
    console.log('add user function in user.js : ', user, updatedRole);
  }

  async removeUser(user) {
    console.log('so long', user);
    let removePortalUser = await functions.httpsCallable('removePortalUser');
    removePortalUser({ email: user })
      .then(function (result) {
        // Read result of the Cloud Function.
        window.location.reload();
      })
      .catch(function (error) {
        // Getting the Error details.
        let code = error.code;
        let message = error.message;
        let details = error.details;

        console.log(code, message, details);
        // ...
      });
  }

  render() {
    //const role = functions.httpsCallable('assignRole')
    //console.log('%cRole', 'background-color:green;color:orange;', role)

    return (
      <Container style={{ paddingTop: '120px' }}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h3>User</h3>
                </TableCell>
                <TableCell align='left'>
                  <h3>Role</h3>
                </TableCell>
                <TableCell align='right'>
                  <Adduser newrole={this.editRole} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.portalUsers.map((portalUser) => (
                <TableRow key={portalUser.email}>
                  <TableCell component='th' scope='row'>
                    {portalUser.email}
                  </TableCell>
                  <TableCell align='left'>{portalUser.userRole}</TableCell>
                  <TableCell align='right'>
                    <EditRole
                      user={portalUser.email}
                      role={portalUser.userRole}
                      newrole={this.editRole}
                      style={{ margin: '0 10px' }}
                    />
                    <DeleteUser
                      user={portalUser.email}
                      removeuser={this.removeUser}
                      style={{ margin: '0 10px' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
