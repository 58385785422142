import React from 'react';
import ReportCard from '../ReportCard/ReportCard';
import Button from '@material-ui/core/Button';
import { db } from '../../../FirebaseConfig';

export default class ReviewSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.db = db;
    this.state = {
      reports: [],
    };
  }

  async componentDidMount() {
    let postdetails = await this.db
      .collection('posts')
      .doc(`${this.props.index}`)
      .get();
    let postdata = postdetails.data();
    if (postdata) this.setState({ image: postdata.postUrlSrc.large });

    let reportsForPost = await this.db
      .collection('posts')
      .doc(`${this.props.index}`)
      .collection('reports')
      .orderBy('whenCreated', 'desc')
      .limit(10)
      .get();
    reportsForPost.forEach((report) => {
      this.state.reports.push({
        parent: this.props.index,
        reportid: report.id,
        report: report.data(),
      });
    });
    this.forceUpdate();
  }

  async approvePost() {
    let reportsForInvalidation = await this.db
      .collection('posts')
      .doc(`${this.props.index}`)
      .collection('reports')
      .orderBy('whenCreated', 'desc')
      .get();
    reportsForInvalidation.forEach((report) => {
      // cycle through reports and mark all as invalid
      this.db
        .collection('posts')
        .doc(`${this.props.index}`)
        .collection('reports')
        .doc(`${report.id}`)
        .update({
          reviewStatus: 'invalid',
          whenUpdated: db.FieldValue.serverTimestamp(),
        });
    });

    // now approve the post
    await this.db.collection('posts').doc(`${this.props.index}`).update({
      approvalStatus: 'approved',
      whenUpdated: db.FieldValue.serverTimestamp(),
    });
  }

  render() {
    if (!this.state && !this.state.post) return <div>Loading...</div>;

    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
            paddingBottom: 50,
            paddingTop: '60px',
            zIndex: 2,
          }}
        >
          <div
            style={{
              width: '362px',
              height: '466px',
              margin: '20px 10px',
              overflow: 'visible',
              position: 'relative',
              transformStyle: 'preserve-3d',
              transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                border: '4px solid #fff',
                overflow: 'hidden',
                display: 'flex',
                flex: 1,
                width: '95%',
                height: '100%',
                position: 'absolute',
                top: 0,
                backfaceVisibility: 'hidden',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.8)',
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(0deg)',
                }}
                src={this.state && this.state.image}
                alt=''
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignContent: 'center',
            }}
          >
            <Button
              style={{
                width: '95%',
                backgroundColor: '#FFF',
              }}
              onClick={(e) => {
                this.approvePost();
              }}
              color='primary'
            >
              Return to Feed
            </Button>
          </div>
          <h5
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignContent: 'flex-start',
            }}
          >
            Reports
          </h5>
          {this.state &&
            this.state.reports.map((report) => {
              return (
                <ReportCard
                  classname={
                    report.report.reviewStatus !== 'pending' ? 'grey' : ''
                  }
                  parent={this.props.index}
                  id={report.reportid}
                  key={report.reportid}
                  report={report.report}
                />
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
