import React from 'react';
import PostCard from '../../Components/UI/PostCard/PostCard';
import Pagination from '../../Components/UI/Pagination/Pagination';
import { db, functions } from '../../FirebaseConfig';
import EventDropdown from '../../Components/UI/EventDropdown/EventDropdown';
import PostsSidebar from '../../Components/UI/PostsSidebar/PostsSidebar';

export default class ContentApproval extends React.Component {
  constructor() {
    super();
    this.state = {
      clickItem: null,
      setClickItem: null,
      posts: [],
      filteredPosts: [],
      currentPage: 1,
      postsPerPage: 18,
      approved: [],
      rejected: [],
      eventsList: [],
      tags: [],
      filterBy: 'All',
      selectedPost: {},
      openSidebar: false,
    };
    this.db = db;
    this.rejectPost = this.rejectPost.bind(this);
    this.approvePost = this.approvePost.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.filterList = this.filterList.bind(this);
    this.handleClicked = this.handleClicked.bind(this);
  }
  async componentDidMount() {
    this.getPendingPosts(null);
    this.getEvents(null);
  }

  async getPendingPosts(startDate) {
    if (this.cancelListen) this.cancelListen();
    this.cancelListen = this.db
      .collection('posts')
      .where('approvalStatus', '==', 'pending')
      .where('isDeleted', '==', false)
      .onSnapshot((snapshot) => {
        this.setState({
          posts: snapshot.docs.map((doc) => ({
            postID: doc.id,
            data: doc.data(),
          })),
        });
      });
  }

  async getEvents() {
    let events = await this.db.collection('postEvents').get();
    events.forEach((event) => {
      console.log('getting events', this.state.eventsList);
      const eventData = event.data();
      eventData.id = event.id;
      this.setState((previousState) => ({
        eventsList: [...previousState.eventsList, eventData],
      }));
    });
  }

  /// Mark post as rejected.
  rejectPost(postId, userId) {
    localStorage.setItem(postId, 'rejected');
    // call https callable
    const postStatus = functions.httpsCallable('updatePostStatus');
    return postStatus({
      status: 'rejected',
      postId,
      userId,
    });
  }

  /**
   * Mark post as approved.
   *
   * In addition to marking this post as approved, a post event is created in the 'events' collection.
   *
   * @param {*} postId ID of the post.
   * @param {*} userId ID of the post creator.
   */
  approvePost(postId, userId) {
    localStorage.setItem(postId, 'approved');
    // call https callable
    const postStatus = functions.httpsCallable('updatePostStatus');
    return postStatus({
      status: 'approved',
      postId,
      userId,
    });
  }

  filterList(eventTitle) {
    this.setState({ filterBy: eventTitle });
    if (eventTitle && eventTitle !== 'all') {
      const selectedEvent = this.state.eventsList.filter((el) =>
        el.title.includes(eventTitle)
      );
      const activeTags = selectedEvent[0].tags;
      this.getTags(activeTags);
    }
  }

  handleClicked() {
    this.setState({ openSidebar: true });
  }

  getTags(activeTags) {
    this.setState({ tags: activeTags });
  }

  getUserInfo(fullpost) {
    this.setState({ selectedPost: fullpost });
    this.setState({ openSidebar: true });
  }

  render() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;

    let currentPosts = this.state.posts
      .slice(indexOfFirstPost, indexOfLastPost)
      .sort(function compare(a, b) {
        var dateA = new Date(a.data.whenCreated.seconds);
        var dateB = new Date(b.data.whenCreated.seconds);
        return dateB - dateA;
      });

    if (this.state.tags.length > 0 && this.state.filterBy !== 'all') {
      currentPosts = currentPosts.filter((post) => {
        for (let tag of this.state.tags)
          if (post.data.tags.includes(tag)) return true;
        return false;
      });
    }

    // Change page
    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <div
            style={{
              display: 'flex',
              marginTop: 80,
              justifyContent: 'flex-end',
              padding: '0 40px',
            }}
          >
            <EventDropdown
              events={this.state.eventsList}
              filterevent={this.filterList}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                paddingTop: '10px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: '60px',
                perspective: '4400px',
                perspectiveOrigin: '50% 50%',
                justifyContent: 'flex-start',
              }}
            >
              {currentPosts.map((post, index) => (
                <PostCard
                  isInSidebar={false}
                  clickHandler={() => this.handleClicked()}
                  key={index}
                  image={post.data.postUrlSrc.medium}
                  userid={post.data.userId}
                  status={post.data.approvalStatus}
                  postid={post.postID}
                  page={this.state.currentPage}
                  username={post.data.username}
                  avatar={post.data.avatarUrlSrc.original}
                  created={post.data.whenCreated.seconds}
                  getUserInfo={(post) => this.getUserInfo(post)}
                  fullPost={post}
                  setStatusRejected={() =>
                    this.rejectPost(post.postID, post.data.userId)
                  }
                  setStatusApproved={() =>
                    this.approvePost(post.postID, post.data.userId)
                  }
                />
              ))}
            </div>
            <Pagination
              postsPerPage={this.state.postsPerPage}
              totalPosts={this.state.posts.length}
              paginate={paginate}
              currentPage={this.state.currentPage}
            />
          </div>
        </div>
        {this.state.openSidebar ? (
          <PostsSidebar
            closedrawer={() => this.setState({ openSidebar: false })}
            opendrawer={this.state.openSidebar}
            selectedpost={this.state.selectedPost}
            eventlist={this.state.eventsList}
            getUserInfo={(postId, userId) => {
              this.getUserInfo(postId, userId);
            }}
            approvepost={(postId, userId) => {
              this.approvePost(postId, userId);
            }}
            rejectpost={(postId, userId) => {
              this.rejectPost(postId, userId);
            }}
          />
        ) : null}
      </div>
    );
  }
}
